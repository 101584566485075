import React from 'react'
import AboutHome from '../components/AboutHome'
import BlogRoll from '../components/BlogRoll/'
import HomeSlider from '../components/HomeSlider'
import Layout from '../components/Layout/'
import QuoteHome from '../components/QuoteHome'
import TeachersHome from '../components/TeachersHome'
import StudentPortal from '../components/StudentPortal'

import * as GS from '../components/GlobalStyles/styles'
import ThisIs from '../components/ThisIs'
import LocationHome from '../components/LocationHome'

const Home = () => (
  <Layout>
    <div>
      <HomeSlider />
      <AboutHome />
      <QuoteHome />
      <TeachersHome />
      <GS.Divider data-content="Fundamental II ao ensino médio" />
      <StudentPortal />
      <ThisIs />
      <LocationHome />
      <BlogRoll unique={true} />
    </div>
  </Layout>
)

export default Home
