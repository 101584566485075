import styled from 'styled-components'
import { colors } from '../../utils/global'

export const Wrapper = styled.div`
  padding: 36px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const Pin = styled.img`
  width: 80px;
`

export const H2 = styled.h2`
  color: white;
  margin: 12px 0;
`

export const H4 = styled.h4`
  color: white;
  font-weight: 600;
  text-align: center;
  margin: 12px 0;
`

export const H5 = styled.h5`
  color: white;
  font-weight: 500;
  text-align: center;
  margin: 12px 0;
`
