import React from 'react'
import { Parallax } from 'react-parallax'
import * as GS from '../GlobalStyles/styles'
import img1 from '../../img/map-bg.jpg'
import img2 from '../../img/colegio-exato-pin-icone.svg'

import * as S from './styles'

const LocationHome = () => (
  <Parallax blur={4} bgImage={img1} bgImageAlt="the cat" strength={400}>
    <S.Wrapper>
      <S.Pin src={img2} alt="Localização" />
      <S.H2>Onte Estamos</S.H2>
      <S.H4>
        Av. Faiad Hanna, 6<br />
        Cidade Jardim
        <br />
        Anápolis - GO
        <br />
        CEP 75053-520
      </S.H4>
      <S.H5>Clique para visualizar o mapa.</S.H5>
      <GS.YellowButton margin="0">Clique aqui</GS.YellowButton>
    </S.Wrapper>
  </Parallax>
)

export default LocationHome
