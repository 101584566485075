import React from 'react'
import { Parallax } from 'react-parallax'
import img1 from '../../img/home-quote-bg.jpg'

import * as S from './styles'

const QuoteHome = () => (
  <Parallax blur={4} bgImage={img1} bgImageAlt="the cat" strength={400}>
    <S.Quote>
      “Educar não é ensinar respostas, educar é ensinar a pensar."
      <br />
      <span>Rubem Alves</span>
    </S.Quote>
  </Parallax>
)

export default QuoteHome
