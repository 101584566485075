import styled from 'styled-components'
import { colors } from '../../utils/global'

export const Container = styled.div`
  display: flex;
  max-width: 840px;
  margin: 0 auto;
  padding: 80px 16px;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-end;

  @media (max-width: 400px) {
    padding: 60px 8px;
  }
`

export const PictureWrapper = styled.div`
  width: 60%;

  @media (max-width: 400px) {
    width: 50%;
  }
`

export const TextWrapper = styled.div`
  width: 100%;
  max-width: 360px;
  text-align: center;
`

export const H2 = styled.h2`
  font-size: 48px;
  color: ${colors.bayOfMany};
  line-height: 1.125;

  z-index: 2;
  position: relative;

  @media (max-width: 640px) {
    font-size: 24px;

    margin-bottom: 4px;
  }

  @media (max-width: 400px) {
    font-size: 18px;
  }
`

export const P = styled.p`
  color: ${colors.bayOfMany};
  font-size: 18px;
  margin: 0 auto 36px;
  max-width: 170px;

  @media (max-width: 640px) {
    font-size: 15px;
    max-width: 130px;
    margin: 0 auto 16px;
  }

  @media (max-width: 400px) {
    font-size: 14px;
    margin: 0 auto 0;
    max-width: 100px;
  }
`

export const ButtonHide = styled.div`
  @media (max-width: 400px) {
    display: none;
  }
`

export const ButtonShow = styled.div`
  margin-top: 32px;

  @media (min-width: 401px) {
    display: none;
  }
`
