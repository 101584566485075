import React from 'react'
import Slider from 'react-slick'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import * as S from './styles'
import * as GS from '../GlobalStyles/styles'
import icon from '../../img/teacher-icon.svg'
import nextArrow from '../../img/slides/right-arrow.png'
import prevArrow from '../../img/slides/left-arrow.png'

import fabricio from '../../img/professores/fabricio.jpg'
import tales from '../../img/professores/tales.jpg'
import karine from '../../img/professores/karine.jpg'
import wanderson from '../../img/professores/wanderson.jpg'
import pauloRicardo from '../../img/professores/paulo-ricardo.jpg'

const SampleNextArrow = (props) => {
  const { onClick } = props
  return (
    <S.NextArrow onClick={onClick}>
      <img src={nextArrow} alt="Próximos" />
    </S.NextArrow>
  )
}

const SamplePrevArrow = (props) => {
  const { onClick } = props
  return (
    <S.PrevArrow onClick={onClick}>
      <img src={prevArrow} alt="Anteriores" />
    </S.PrevArrow>
  )
}

const settings = {
  dots: false,
  infinite: true,
  speed: 350,
  slidesToShow: 4,
  slidesToScroll: 1,
  autoplay: true,
  nextArrow: <SampleNextArrow />,
  prevArrow: <SamplePrevArrow />,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3
      }
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1
      }
    }
  ]
}

const teachers = [
  {
    name: 'Tales',
    picture: tales,
    role: 'Matemática e Física'
  },
  {
    name: 'Karine',
    picture: karine,
    role: 'Química'
  },
  {
    name: 'Wanderson',
    picture: wanderson,
    role: 'Geografia'
  },
  {
    name: 'Paulo Ricardo',
    picture: pauloRicardo,
    role: 'Literatura'
  }
]

const TeachersHome = () => (
  <S.Container>
    <S.TeachersIcon src={icon} alt="Nossos Professores" />
    <GS.Title margin="12px 0 4px">Nossos professores</GS.Title>
    <S.STitle>Conheça quem são os feras por trás do Exato!</S.STitle>
    <S.TeachersSlider>
      <Slider {...settings}>
        {teachers.map((teacher) => (
          <div key={teacher.name}>
            <S.UniqueSlide>
              <img src={teacher.picture} alt={teacher.name} />
              <h3>{teacher.name}</h3>
              <p>{teacher.role}</p>
            </S.UniqueSlide>
          </div>
        ))}
      </Slider>
    </S.TeachersSlider>
    <GS.YellowLink to="/equipe" margin="40px 0 0">
      Ver todos
    </GS.YellowLink>
  </S.Container>
)

export default TeachersHome
