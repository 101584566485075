import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  max-width: 840px;
  margin: 0 auto;
  padding: 80px 16px;
  flex-wrap: wrap;
  justify-content: space-between;
`

export const PictureWrapper = styled.div`
  width: 54%;
  margin: 16px 0 0;

  @media (max-width: 768px) {
    width: 100%;
  }
`

export const TextWrapper = styled.div`
  margin: 16px 0 0;
  width: 43%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media (max-width: 768px) {
    width: 100%;
  }
`
