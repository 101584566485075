import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

import * as S from './styles'
import * as GS from '../GlobalStyles/styles'

const AboutHome = () => {
  const { check, exato } = useStaticQuery(
    graphql`
      query {
        check: file(relativePath: { eq: "colegio-exato-check-mark.png" }) {
          childImageSharp {
            fluid(maxWidth: 2400) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        exato: file(relativePath: { eq: "colegio-exato-fachada.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 2400) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )
  return (
    <S.Container>
      <GS.TitleWrapper>
        <div>
          <Img fluid={check.childImageSharp.fluid} />
        </div>
        <GS.Title>O Exato</GS.Title>
      </GS.TitleWrapper>
      <S.PictureWrapper>
        <Img fluid={exato.childImageSharp.fluid} />
      </S.PictureWrapper>
      <S.TextWrapper>
        <GS.Text margin="0 0 20px">
          Colégio Exato prioriza um ensino responsável, de qualidade,
          enfatizando o desenvolvimento criativo do aluno e a junção de suas
          experiências na coesão de seu EU autônomo com o mundo e na
          manifestação do pensamento afetivo e solidário na sua amplitude.
        </GS.Text>
        <GS.YellowLink to="/o-exato" margin="auto 0 0">
          Saiba mais
        </GS.YellowLink>
      </S.TextWrapper>
    </S.Container>
  )
}

export default AboutHome
