import styled from 'styled-components'
import { colors } from '../../utils/global'

export const Quote = styled.h3`
  padding: 60px 8px;
  color: ${colors.white};
  max-width: 820px;
  font: normal normal 800 20px/24px 'Montserrat';
  margin: 0 auto;
  text-align: center;

  span {
    font-weight: 500;
    line-height: 2;
  }
`
