import React from 'react'
import Slider from 'react-slick'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import * as S from './styles'

const settings = {
  dots: false,
  infinite: true,
  speed: 350,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true
}

const HomeSlider = () => {
  const {
    slide1Grande,
    slide1Pequeno,
    slide3Grande,
    slide3Pequeno
  } = useStaticQuery(
    graphql`
      query {
        slide1Grande: file(relativePath: { eq: "slides/mat-2024-grande.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 2400) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        slide1Pequeno: file(
          relativePath: { eq: "slides/mat-2024-pequeno.jpg" }
        ) {
          childImageSharp {
            fluid(maxWidth: 2400) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        slide3Grande: file(
          relativePath: { eq: "slides/colegio-exato-slide-3-grande.jpg" }
        ) {
          childImageSharp {
            fluid(maxWidth: 2400) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        slide3Pequeno: file(
          relativePath: { eq: "slides/colegio-exato-slide-3-pequeno.jpg" }
        ) {
          childImageSharp {
            fluid(maxWidth: 2400) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )
  return (
    <div style={{ position: 'relative', zIndex: '-1' }}>
      <Slider {...settings}>
        <div>
          <S.Grande>
            <Img fluid={slide1Grande.childImageSharp.fluid} />
          </S.Grande>
          <S.Pequeno>
            <Img fluid={slide1Pequeno.childImageSharp.fluid} />
          </S.Pequeno>
        </div>
        {/* <div>
          <S.Grande>
            <Img fluid={slide3Grande.childImageSharp.fluid} />
          </S.Grande>
          <S.Pequeno>
            <Img fluid={slide3Pequeno.childImageSharp.fluid} />
          </S.Pequeno>
        </div> */}
      </Slider>
    </div>
  )
}

export default HomeSlider
