import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

import * as S from './styles'
import * as GS from '../GlobalStyles/styles'

import img1 from '../../img/colegio-exato-coracao-icone.svg'
import img2 from '../../img/colegio-exato-computador-icone.svg'
import img3 from '../../img/colegio-exato-mira-icone.svg'
import img4 from '../../img/colegio-exato-familia-icone.svg'

const ThisIs = () => {
  const { check, exato } = useStaticQuery(
    graphql`
      query {
        check: file(relativePath: { eq: "colegio-exato-check-mark.png" }) {
          childImageSharp {
            fluid(maxWidth: 2400) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        exato: file(relativePath: { eq: "temp-exato.png" }) {
          childImageSharp {
            fluid(maxWidth: 2400) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )
  return (
    <S.Container>
      <GS.TitleWrapper>
        <div>
          <Img fluid={check.childImageSharp.fluid} />
        </div>
        <GS.Title>Isso é Exato</GS.Title>
      </GS.TitleWrapper>
      <S.Wrapper>
        <S.Content>
          <div>
            <img src={img1} alt="Coração" />
          </div>
          <h2>
            Valores
            <br />
            Humanos
          </h2>
          <p>Levamos para a sala de aula a prática de valores e princípios.</p>
        </S.Content>
        <S.Content>
          <div>
            <img src={img2} alt="Computador" />
          </div>
          <h2>
            Educação
            <br />
            Digital
          </h2>
          <p>
            Acompanhamos a evolução tecnológica e a usamos para uma educação
            inovadora.
          </p>
        </S.Content>
        <S.Content>
          <div>
            <img src={img3} alt="Mira" />
          </div>
          <h2>
            Preparação que
            <br />
            dá resultado
          </h2>
          <p>
            Preparamos nossos alunos para muito conhecimento e grandes
            conquistas.
          </p>
        </S.Content>
        <S.Content>
          <div>
            <img src={img4} alt="Família" />
          </div>
          <h2>
            Proximidade
            <br />
            com a família
          </h2>
          <p>
            Valorizamos o vínculo entre escola e família e sua participação no
            aprendizado.
          </p>
        </S.Content>
      </S.Wrapper>
    </S.Container>
  )
}

export default ThisIs
