import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

import * as S from './styles'
import * as GS from '../GlobalStyles/styles'

const StudentPortal = () => {
  const { student } = useStaticQuery(
    graphql`
      query {
        student: file(relativePath: { eq: "colegio-exato-portal-aluna.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 2400) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )
  return (
    <S.Container>
      {/* <S.PictureWrapper>
        <Img fluid={student.childImageSharp.fluid} />
      </S.PictureWrapper> */}
      <S.TextWrapper>
        <S.H2>
          Portal
          <br />
          do Aluno
        </S.H2>
        <S.P>Tenha acesso ao seu desenpenho!</S.P>
        <S.ButtonHide>
          <GS.YellowButton
            href="https://aluno.escolarmanageronline.com.br/colegioexato"
            target="_blank"
            margin="auto 0 0"
          >
            Clique aqui para entrar
          </GS.YellowButton>
        </S.ButtonHide>
      </S.TextWrapper>
      <S.ButtonShow>
        <GS.YellowButton
          href="https://aluno.escolarmanageronline.com.br/colegioexato"
          target="_blank"
          margin="auto 0 0"
        >
          Saiba mais
        </GS.YellowButton>
      </S.ButtonShow>
    </S.Container>
  )
}

export default StudentPortal
