import styled from 'styled-components'

export const Grande = styled.div`
  @media (max-width: 768px) {
    display: none;
  }
`

export const Pequeno = styled.div`
  @media (min-width: 769px) {
    display: none;
  }
`
