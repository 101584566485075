import styled from 'styled-components'
import { colors } from '../../utils/global'

export const Container = styled.div`
  background: ${colors.mercury};
  display: flex;
  margin: 0 auto;
  padding: 80px 16px 60px;
  flex-wrap: wrap;
  justify-content: center;
`

export const Wrapper = styled.div`
  margin-top: 48px;
  width: 100%;
  max-width: 900px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`

export const Content = styled.div`
  width: calc(25% - 20px);
  border: solid 1px ${colors.doveGray};
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;

  & > div {
    margin-top: -20px;
    min-height: 100px;
  }

  img {
    max-width: 120px;
  }

  h2 {
    color: ${colors.bayOfMany};
    font-size: 20px;
    text-align: center;
    margin-bottom: 0;

    &:after {
      content: '';
      display: block;
      width: 48px;
      height: 2px;
      background: ${colors.bayOfMany};
      margin: 18px auto;
    }
  }

  p {
    text-align: center;
    font-size: 14px;
    margin: 0 16px 20px;
  }

  @media (max-width: 880px) {
    width: calc(50% - 12px);
  }

  @media (max-width: 480px) {
    width: calc(100% - 12px);
  }
`
