import styled from 'styled-components'
import { colors } from '../../utils/global'

export const Container = styled.div`
  max-width: 1080px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 80px 16px;
`

export const TeachersIcon = styled.img`
  width: 160px;
`

export const STitle = styled.h4`
  font: normal normal 500 20px/24px 'Montserrat';
  margin: 0;
  text-align: center;
`

export const TeachersSlider = styled.div`
  margin: 36px 0 0;
  width: calc(96% - 40px);
`

export const UniqueSlide = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border: solid 1px #000;
  margin: 4px;
  padding: 6px;

  h3 {
    color: ${colors.bayOfMany};
    margin: 18px 0 0;

    &:after {
      content: '';
      display: block;
      margin: 10px auto;
      width: 50%;
      height: 2px;
      background: ${colors.bayOfMany};
    }
  }

  img {
    width: 100%;
  }

  p {
    color: ${colors.shark};
    margin: 12px 0 24px;
    text-align: center;
  }
`

export const PrevArrow = styled.div`
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -42px;

  img {
    max-width: 36px;
    opacity: 0.7;
  }
`

export const NextArrow = styled.div`
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -42px;

  img {
    max-width: 36px;
    opacity: 0.7;
  }
`
